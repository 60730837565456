import { PayloadAction } from "@reduxjs/toolkit";

import {
  NewsTopicListInterface,
  NewsTopicState,
} from "../types";
import { createSlice } from "../../../utils/@reduxjs/toolkit";

export const initialState: NewsTopicState = {
  FeaturedList: [],
  newsTopicList: [],
  categoryList: [],
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: "",
  newsDetails: {
    _id: "",category:"",
    catgoryId: "",
    coverImg: "",
    coverImgfile: "",
    date: "",
    longDescription: "",
    thumbImg: "",
    thumbImgFile: "",
    title: "",
  },
};

export const useClinicSlice = createSlice({
  name: "newsTopicState",
  initialState,
  reducers: {
    doGetFeatured: (state) => {},
    setFeaturedList: (
      state,
      action: PayloadAction<Array<NewsTopicListInterface>>
    ) => {
      state.FeaturedList = action.payload;
    },
    setCategoryList: (
      state,
      action: PayloadAction<Array<NewsTopicListInterface>>
    ) => {
      state.categoryList = action.payload;
    },
    doGetNewsTopics: (state, action: PayloadAction<{ callback: any }>) => {},
    doGetCategory: (state) => {},
    setNewsTopicList: (
      state,
      action: PayloadAction<Array<NewsTopicListInterface>>
    ) => {
      state.newsTopicList = action.payload;
    },
    setNewsDetailsData: (state, action: PayloadAction<any>) => {
      state.newsDetails = action.payload;
    },
    doGetNewsById: (state, action: PayloadAction<any>) => {},
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
  },
});

export const { reducer, actions, name: SliceKey } = useClinicSlice;

export interface BookingState {
  ReservationinformationForm: ReservationinformationInterface;
  porcessingPayment: boolean;
  paymnetsecreat: string;
  pivateKey: any;
  merchantId: string;
  publicKeyId: string;
  sandbox: boolean;
  signature: string;
  payload: {};
  // amazonePayData: AmazonePayDataInterface;
}
export interface AmazonePayDataInterface {
  merchantId: string;
  publicKeyId: string;
  sandbox: boolean;
  signature: string;
  payload: {};
}
export interface ReservationinformationInterface {
  fullName: string;
  address: string;
  email: string;
  tel: string;
  dob: string;
  dateOfVaccination: string;
}
export interface BookingPackageSeasonListInterface {
  title: string;
  updatedAt: string;
  _id: string;
  createdAt: string;
  endDate: string;
  isActive: boolean;
  startDate: string;
  timeSlot: [
    {
      endTime: any;
      startTime: any;
      _id: string;
    }
  ];
}
export interface BookingPackageRoomCategoryListInterface {
  title: string;
  _id: string;
}
export enum TIME_SLOT_TYPE_ENUM {
  SINGLE = 1,
  MULTIPLE = 2,
}
export interface availabilityItem {
  _id: any;
  result: string;
  time: [];
  total: number;
}
export interface SessionItem {
  _id: string;

  title: string;

  isActive: 1;

  startDate: string;

  endDate: string;

  timeSlot: [];

  createdAt: string;

  updatedAt: string;
}
export interface sessionPrice {
  childrenExtraPrice: number;
  createdAt: string;
  dogExtraPrice: number;
  packageId: string;
  peopleExtraPrice: number;
  seasonId: string;
  updatedAt: string;
  weekDayPrice: number;
  weekEndPrice: number;
  _id: string;
}

export interface THoliday {
  childrenExtraPrice: number;
  createdAt: string;
  startDate: string;
  packageId: string;
  _id: string;
}

export interface TPackageCategory {
  title: string;
  _id: string;
}


export interface BookingPackageInterface {
  _id: string;
  title: string;
  helpText: string;
  maxPeople: number;
  maxChild: number;
  maxBigDogs: number;
  maxMediumDogs: number;
  maxSmallDogs: number;
  weekendPrice: number;
  price: number;
  shoes: number;
  inventory: number;
  bbq: number;
  timeSlot: TIME_SLOT_TYPE_ENUM;
  roomCategory: string;
  package_images: [
    {
      _id: string;
      file: string;
      fileName: string;
    }
  ];
  season: SessionItem[];
  availability?: availabilityItem[];
  season_price: sessionPrice[];
  holidays: THoliday[];
  createdAt: string;
}
export interface BookingPackageAddNewInterface {
  smallDogs: number;
  bigAndMediumDogs: number;
  people: number;
  child: number;
  packageList: BookingPackageAddInterface[];
}
export interface BookingPackageSearchInterface {
  smallDogs: number;
  bigAndMediumDogs: number;
  category:string;
  people: number;
  child: number;
}
export interface BookingPackageAddInterface {
  date: string;
  timeSlot: TIME_SLOT_TYPE_ENUM;
  packageID: string;
  title: string;
  startTime: string;
  endTime: string;
  timeSlotIndex: number;
}
export interface BookingFilterList {
  people: number;
  bigAndMediumDogs: number;
  smallDogs: number;
}

export interface BookingPackagUserInterface {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  mobNumber: string;
  mobileNumber: string;
  englishFirstName: string;
  englishLastName: string;
  dateOfVaccination: string;
  dob: string;
  picnicCharges: number;
  address: string;
  state: string;
  district: string;
  city: string;
  pincode: string;
  apartment: string;
  dogs: [
    {
      _id: string;
      userId: string;
      dogName: string;
      breedName: string;
      dateOfVaccine: string;
      file: string;
    }
  ];
  picnicFormData: PicnicPriceInterface;
  carParkingFormData: CarParkingInterface;
  carParkingFormDataList: CarParkingInterface[];
  // new one
  picnic: number;
  sameAsUser: boolean;
  carPersonFirstName: string;
  carPersonLastName: string;
  carPersonEmail: string;
  carPersonmobileNumber: string;
  carPersonEnglishFirstName: string;
  carPersonEnglishLastName: string;
  description: string;
  arrivalTime: string;
}
export interface SelectedPackageBookingInterface {
  _id: string;
  cartId: string;
  title: string;
  date: string;
  roomCategory: string;
  bbq: number;
  bigAndMediumDogs: number;
  child: number;
  createdAt: string;
  endTime: string;
  helpText: string;
  inventory: number;
  maxBigDogs: number;
  maxChild: number;
  maxMediumDogs: number;
  maxPeople: number;
  maxSmallDogs: number;
  noOfBbq: number;
  noOfShoes: number;
  packageID: string;
  people: number;
  price: number;
  quantity: number;
  shoes: number;
  smallDogs: number;
  startTime: string;
  shoesEnable: boolean;
  bbqEnable: boolean;
  userShoesEnable: boolean;
  bigAndMediumDogsEnable: boolean;
  smallDogsEnable: boolean;
  dogsEnable: boolean;
  dogs: number;
  timeSlot: TIME_SLOT_TYPE_ENUM;
  addOns: AddOptionsInterface[];
  cartAddOn: CartAddOnInterface[];
  addOPtionForm: AddOptionsInterface;
  dogType: DogTypeEnum;
  maxBigAndMediumDogs: number;
  pplExtraCharges: number;
  childrenExtraCharges: number;
  bigAndMediumDogExtraCharges: number;
  smallDogExtraCharges: number;
  packageBasePrice: number;
  dogExtraCharges: number;
  maxNoOfDogs: number;
  extraPeople: number;
  extraChild: number;
  extraSmallDogs: number;
  extraBigAndMediumDogs: number;
  extraDogs: number;
}
export enum DogTypeEnum {
  BIG_AND_MEDIUM_DOG = 1,
  SMALL_DOG = 2,
  BOTH = 3,
  COMBINE_DOGS = 4,
}
export enum PackageAddOnTypeEum {
  PACKAGE_BASED = 1,
  PEOPLE_BASED = 2,
}
export interface AddOptionsInterface {
  _id: string;
  addOnId: string;
  addOnPeople: number;
  packageId: string;
  title: string;
  price: number;
  minPeople: number;
  maxPeople: number;
  type: PackageAddOnTypeEum;
  createdAt: string;
  updatedAt: string;
  noOfAddOnPerDay: number;
  image: string;
  description: string;
}

export interface CartAddOnInterface {
  addOnId: string;
  addOnPeople: number;
  cartItemId: string;
  userId: string;
  _id: string;
  addOnBasePrice: number;
  addOnPrice: number;
  title: string;
  addOnType: PackageAddOnTypeEum;
}
export interface SelectedPackagePriceInterface {
  _id: string;
}
export interface CarParkingInterface {
  _id: string;
  sameAsUser: boolean;
  carPersonFirstName: string;
  carPersonLastName: string;
  carPersonEmail: string;
  carPersonmobileNumber: string;
  carPersonEnglishFirstName: string;
  carPersonEnglishLastName: string;
  description: string;
  arrivalTime: any;
}
export interface PicnicPriceInterface {
  _id: string;
  picnic: any;
}
export interface BookingConfirmationDetailsInterface {
  _id: string;
  picnicCharges: string;
  totalPrice: string;
  picnicAvailable: boolean;
  finalAmount: string;
  tax: string;
  bookingUserDetails: BookingPackagUserInterface;
  bookingPackageDetailsList: SelectedPackageBookingInterface[];
  bookingPackageDetailsFromList: SelectedPackageBookingInterface;
}
export enum PaymentMethodEnum {
  STRIPE = 1,
  AMAZON_PAY = 2,
  All = 0,
}

export interface DateInterface {
  startDate: string;
  endDate: string;
}
export interface TMCInterface {
  id: string;
  tmc: boolean;
}
export interface PaymentMethodInterface {
  id: string;
  paymentMethod: PaymentMethodEnum;
}
export interface SiteSettingInterface {
  _id: string;
  picnicPrice: number;
  maxPicnicPeople: number;
  minPicnicPeople: number;
  homePageTime1?:string;
  homePageTime2?:string;
  homePageTime3?:string;
  homePageTime4?:string
}
export interface BookingState {
  ReservationinformationForm: ReservationinformationInterface;
  bookingpackageList: BookingPackageInterface[];
  packageCategory:TPackageCategory[];
  packageTotalPriceDetails: SelectedPackagePriceInterface;
  bookingConfirmationDetails: BookingConfirmationDetailsInterface;
  packageListFrom: BookingPackageAddNewInterface;
  searchFrom: BookingPackageSearchInterface;
  BookingSeasonList: BookingPackageSeasonListInterface[];
  BookingRoomCategoryList: BookingPackageRoomCategoryListInterface[];
  loading: boolean;
  date: any | "";
  createdOnFilter: number | 0;
  dateArray: DateInterface;
  tmcForm: TMCInterface;
  siteSetting: SiteSettingInterface;
  paymentMethodForm: PaymentMethodInterface;
  showPaymentProceed: boolean;
}
export type InitialAuthState = BookingState;

import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import CustomToast from "../../../Components/CustomToast";
import CatchBlockFunction from "../../../Components/hooks/CatchBlockFunction";
import { AxiosResponse } from "axios";
import {
  doGeTopicList,
  doGetNewsById,
  doGetTopicForNHomePageList,
} from "../../../utils/request";
import { SelectPageNo, SelectPageSize, SelectSearch } from "./selector";

export function* doGetFeaturedRequest(action: { payload: any }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(doGetTopicForNHomePageList);
    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setFeaturedList(response.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* doGetNewsTopicsListRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const response: AxiosResponse = yield call(
      doGeTopicList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${
        searchText.length > 0 ? "&search=" + searchText : ""
      }`
    );
    // if (response && !response.data.statusCode) {
    if (response && !response.status) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(
      actions.setTotalRow(response.data.meta ? response.data.meta.total : 0)
    );
    yield put(actions.setNewsTopicList(response.data.results));
    yield put(actions.setCategoryList(response.data.title));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetListByIdRequest(action: { payload: string }) {
  yield delay(500);
  if (action.payload.length === 0) {
    CustomToast("please select", "ERROR");
    return;
  }

  try {
    const response: AxiosResponse = yield call(doGetNewsById, action.payload);

    if (response && !response.data) {
      CustomToast(response.data.message, "ERROR");
      return;
    }
    yield put(actions.setNewsDetailsData(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* NewsTopicRepoSaga() {
  yield takeLatest(actions.doGetFeatured, doGetFeaturedRequest);
  yield takeLatest(actions.doGetNewsTopics, doGetNewsTopicsListRequest);
  yield takeLatest(actions.doGetNewsById, doGetListByIdRequest);
}

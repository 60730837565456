import { PayloadAction } from "@reduxjs/toolkit";

import {
  GalleryFeaturedListInterface,
  GalleryListInterface,
  GalleryState,
  GalleryTitleListInterface,
} from "../types";
import { createSlice } from "../../../utils/@reduxjs/toolkit";

export const initialState: GalleryState = {
  galleryFeaturedList: [],
  galleryList: [],
  galleryTitleList: [],
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: "",
};

export const useClinicSlice = createSlice({
  name: "galleryState",
  initialState,
  reducers: {
    doGetFeaturedPhotos: (state) => {},
    setGalleryFeaturedList: (
      state,
      action: PayloadAction<Array<GalleryFeaturedListInterface>>
    ) => {
      state.galleryFeaturedList = action.payload;
    },
    doGetGallery: (state, action: PayloadAction<{ callback: any }>) => {},
    setGalleryList: (
      state,
      action: PayloadAction<Array<GalleryListInterface>>
    ) => {
      state.galleryList = action.payload;
    },
    setGalleryTitleList: (
      state,
      action: PayloadAction<Array<GalleryTitleListInterface>>
    ) => {
      state.galleryTitleList = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
  },
});

export const { reducer, actions, name: SliceKey } = useClinicSlice;

import { configureStore } from "@reduxjs/toolkit";
import { createInjectorsEnhancer, forceReducerReload } from "redux-injectors";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";

import { createReducer } from "./reducers";
const IS_PRODUCTION = true;

export function configureAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  // Create the store with saga middleware
  const logger = createLogger({
    collapsed: true,
    duration: true,
    timestamp: true,
  });
  let middlewares = [sagaMiddleware];
  if (!IS_PRODUCTION) {
    //@ts-ignore
    middlewares.push(logger);
  }
  // LOG ONLY IN DEVELOPMENT/STAGING PRODUCTION OPTIMIZATIONS
  if (IS_PRODUCTION) {
    middlewares = [...middlewares];
  }
  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ];

  const store = configureStore({
    reducer: createReducer(),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({serializableCheck: false}).concat(middlewares),
    devTools:
      /* istanbul ignore next line */
      IS_PRODUCTION || process.env.PUBLIC_URL?.length > 0,
    enhancers,
  });

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  //@ts-ignore
  if (module.hot) {
    //@ts-ignore
    module.hot.accept("./reducers", () => {
      forceReducerReload(store);
    });
  }

  return store;
}

import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import {
  doAddPackageList,
  doGetBookingPackage,
  doGetBookingPackageDetails,
  doGetBookingPackageSeasonList,
  doGetPackageCategory,
  doGetSiteSettingList,
  doUpdateBookingdata,
} from "../../../utils/request";
import {
  SelectBookingSearchFrom,
  SelectPaymentMethodForm,
  SelectdateArray,
  selectBookingPackageAddFormList,
  selectBookingPackageDetailsList,
  selectBookingUserDetails,
} from "./selector";
import {
  BookingPackagUserInterface,
  BookingPackageAddNewInterface,
  BookingPackageSearchInterface,
  DateInterface,
  PaymentMethodInterface,
  SelectedPackageBookingInterface,
} from "../types";
import CustomToast from "../../../Components/CustomToast";

import CatchBlockFunction from "../../../Components/hooks/CatchBlockFunction";
import { AxiosResponse } from "axios";
import { getSecratKeyAPI } from "../../../utils/request";
import moment from "moment";
export function* getSecratKeyRequest() {
  yield delay(500);
  try {
    const form: PaymentMethodInterface = yield select(SelectPaymentMethodForm);
    const response: AxiosResponse = yield call(
      getSecratKeyAPI,
      `?paymentMethod=${form.paymentMethod}`
    );
    if (response && !response.data) {
      CustomToast(response.data?.message, "ERROR");
      return;
    }
    if (
      (response.data?.key && response.data?.key.length > 0) ||
      (response.data?.publicKeyId && response.data?.publicKeyId.length > 0)
    ) {
      if (response.data?.paymentMethod == 1) {
        yield put(actions.setPaymentMethod(response.data?.paymentMethod));
        yield put(actions.setporcessingPayment(true));
        yield put(
          actions.setpaymnetsecreat(
            response.data?.key ? response.data?.key : ""
          )
        );
        yield put(
          actions.setPivateKey(
            response.data?.public_key ? response.data?.public_key : ""
          )
        );
      } else {
        yield put(actions.setPaymentMethod(response.data?.paymentMethod));
        yield put(actions.setporcessingPayment(true));
        yield put(
          actions.setAmazoneData(
            response.data?.payload ? response.data?.payload : ""
          )
        );
        yield put(
          actions.setAmazoneDataSignature(
            response.data?.signature ? response.data?.signature : ""
          )
        );
        yield put(
          actions.setAmazoneDataSandbox(
            response.data?.sandbox ? response.data?.sandbox : ""
          )
        );
        yield put(
          actions.setAmazoneDataPublicKeyId(
            response.data?.publicKeyId ? response.data?.publicKeyId : ""
          )
        );
        yield put(
          actions.setAmazoneDataMerchantId(
            response.data?.merchantId ? response.data?.merchantId : ""
          )
        );
      }
    } else {
      CustomToast(response.data?.message, "ERROR");
      return;
    }
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* doGetBookingPackageListRequest() {
  yield delay(500);
  const today = moment();
  try {
    const data: BookingPackageSearchInterface = yield select(
      SelectBookingSearchFrom
    );

    const DateFilter: DateInterface = yield select(SelectdateArray);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      doGetBookingPackage,
      `?people=${data?.people}&category=${data?.category}&child=${data?.child}&bigAndMediumDogs=${
        data?.bigAndMediumDogs
      }&smallDogs=${data?.smallDogs}&startDate=${
        DateFilter.startDate.length > 0
          ? moment(DateFilter.startDate).toISOString()
          : today.toISOString()
      }&endDate=${
        DateFilter.endDate.length > 0
          ? moment(DateFilter.endDate).toISOString()
          : today.toISOString()
      }`
    );
    yield put(actions.setLoading(false));
    if (response && !response.status) {
      CustomToast(response.data?.message, "ERROR");
      return;
    }
    yield put(actions.setBookingPackageList(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetPackageCategoryListRequest() {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(doGetPackageCategory);
    yield put(actions.setLoading(false));
    if (response && !response.status) {
      CustomToast(response.data?.message, "ERROR");
      return;
    }
    yield put(actions.setPackageCategoryList(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doAddBookingPackageListRequest(action: {
  payload: { callback: any };
}) {
  yield delay(500);
  const data: BookingPackageAddNewInterface = yield select(
    selectBookingPackageAddFormList
  );
  if (data?.packageList.length == 0) {
    CustomToast("パッケージをお選びください", "ERROR");
    return;
  }
  if (
    data?.people == 0 &&
    (data?.bigAndMediumDogs == 0 || data?.smallDogs == 0)
  ) {
    CustomToast("日付フィルターの値を選択してください。", "ERROR");
    return;
  }
  try {
    const response: AxiosResponse = yield call(doAddPackageList, data);
    if (response && !response.status) {
      CustomToast(response.data?.message, "ERROR");
      return;
    }
    CustomToast(response.data?.message, "SUCCESS");

    yield put(actions.clearSelectedPackageFormFrom());
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetBookingPackageDetailsListRequest() {
  yield delay(500);

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(doGetBookingPackageDetails);
    yield put(actions.setLoading(false));
    if (response && !response.status) {
      CustomToast(response.data?.message, "ERROR");
      return;
    }

    yield put(actions.setTotalDataOfBookingPackage(response.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doUpdateBookingFormDataRequest(action: {
  payload: { callback: any; index: number };
}) {
  yield delay(500);

  const packageForm: SelectedPackageBookingInterface[] = yield select(
    selectBookingPackageDetailsList
  );
  const userForm: BookingPackagUserInterface = yield select(
    selectBookingUserDetails
  );
  yield put(actions.setLoading(true));
  const data = {
    _id: packageForm[action.payload.index]._id,
    cartId: packageForm[action.payload.index].cartId,
    people: packageForm[action.payload.index].people,
    smallDogs: packageForm[action.payload.index].smallDogs,
    bigAndMediumDogs: packageForm[action.payload.index].bigAndMediumDogs,
    addOnId: packageForm[action.payload.index].addOPtionForm?.addOnId,
    addOnPeople: packageForm[action.payload.index].addOPtionForm?.addOnPeople,
    dogs: packageForm[action.payload.index].dogs,
    noOfBbq: packageForm[action.payload.index].noOfBbq,
    userShoesEnable: packageForm[action.payload.index].userShoesEnable,
    child: packageForm[action.payload.index].child,
    sameAsUser: userForm.sameAsUser,
    carPersonFirstName: userForm.carPersonFirstName,
    carPersonLastName: userForm.carPersonLastName,
    carPersonEnglishFirstName: userForm.carPersonEnglishFirstName,
    carPersonEnglishLastName: userForm.carPersonEnglishLastName,
    carPersonEmail: userForm.carPersonEmail,
    carPersonmobileNumber: userForm.carPersonmobileNumber,
    arrivalTime: userForm.arrivalTime,
    description: userForm.description,
    picnic: userForm.picnic,
  };
  try {
    const response: AxiosResponse = yield call(doUpdateBookingdata, data);

    if (response && !response.status) {
      CustomToast(response.data?.message, "ERROR");
      return;
    }
    yield put(actions.setLoading(false));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* doGetBookingPackageSeasomTimeListRequest() {
  yield delay(500);
  const today = moment();

  try {
    const DateFilter: DateInterface = yield select(SelectdateArray);

    const response: AxiosResponse = yield call(
      doGetBookingPackageSeasonList,
      `?startDate=${
        DateFilter.startDate.length > 0
          ? DateFilter.startDate
          : today.format("YYYY/MM/DD")
      }`
    );

    if (response && !response.status) {
      CustomToast(response.data?.message, "ERROR");
      return;
    }
    yield put(actions.setBookingSeasonList(response.data?.data));
    yield put(actions.setBookingRoomCategoryList(response.data?.room_category));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetPicnicMaxValueRequest() {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      doGetSiteSettingList,
      `?pageNo=${1}&pageSize=${10000}"&search=": ""
      "&sortColumn=" + """&sortDir=" ""`
    );

    if (response && !response.status) {
      CustomToast(response.data?.message, "ERROR");
      return;
    }
    yield put(actions.setSiteSettingData(response.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* BookingRepoSaga() {
  yield takeLatest(actions.getSecratKey, getSecratKeyRequest);
  yield takeLatest(
    actions.doBookingPackageList,
    doGetBookingPackageListRequest
  );
  yield takeLatest(
    actions.doPackageCategoryList,
    doGetPackageCategoryListRequest
  );

  yield takeLatest(
    actions.doAddBookingPackageList,
    doAddBookingPackageListRequest
  );
  yield takeLatest(
    actions.doBookingPackageDetailsList,
    doGetBookingPackageDetailsListRequest
  );
  yield takeLatest(actions.doUpdateBookingData, doUpdateBookingFormDataRequest);
  yield takeLatest(
    actions.doBookingPackageSeasomTimeListList,
    doGetBookingPackageSeasomTimeListRequest
  );
  yield takeLatest(actions.doGetPicnicSetting, doGetPicnicMaxValueRequest);
}
